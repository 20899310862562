import { BoundingBox } from '@amzn/genaihub-typescript-client';
import type Konva from 'konva';
import { FC, useContext, useEffect, useRef } from 'react';
import { Image as KonvaImage, Layer, Stage, Transformer } from 'react-konva';
import useImage from 'use-image';
import { ImageModalContext } from '../../ImageModalContext';
import { adjustBoundingBox, ASPECT_RATIO, getSizeWithAspectRatio } from '../utils';

export const ProductPositionCanvas: FC<{
  backgroundImageURL: string;
  productImageURL: string;
  boundingBox: BoundingBox;
  canvasWidth: number;
  ready: boolean;
  onReposition: (newPosition: BoundingBox) => void;
}> = ({ onReposition, ready, canvasWidth, backgroundImageURL, boundingBox, productImageURL }) => {
  const [backgroundImage] = useImage(backgroundImageURL);
  const [productImage] = useImage(productImageURL);
  const imageRef = useRef<Konva.Image>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const { savedEditsImageAspectRatio, studioInputSettings } = useContext(ImageModalContext);
  const aspectRatio = (savedEditsImageAspectRatio || studioInputSettings?.format || '1:1') as ASPECT_RATIO;
  const canvasHeight = getSizeWithAspectRatio({
    width: canvasWidth,
    aspectRatio,
  }).height;

  const multiplier = canvasWidth / (backgroundImage?.naturalWidth || canvasWidth);
  const adjustedBoundingbox = adjustBoundingBox(boundingBox, multiplier);

  useEffect(() => {
    if (imageRef.current && transformerRef.current) {
      transformerRef.current.nodes([imageRef.current]);
    }
  }, [imageRef.current, transformerRef]);

  const getAttributesFromDragEvent = (currentTarget: Konva.Node) => {
    const {
      attrs: { x, y },
    } = currentTarget;
    return onReposition(
      adjustBoundingBox(
        {
          width: adjustedBoundingbox.width,
          height: adjustedBoundingbox.height,
          left: x,
          top: y,
          rotateAngle: Math.round(imageRef?.current?.rotation() || 0),
        },
        1 / multiplier,
      ),
    );
  };

  const getAttributesFromTransformEvent = (currentTarget: Konva.Node) => {
    const {
      attrs: { x, y, width, height, scaleX, scaleY },
    } = currentTarget;

    return onReposition(
      adjustBoundingBox(
        {
          width: width * scaleX,
          height: height * scaleY,
          left: x,
          top: y,
          rotateAngle: Math.round(imageRef?.current?.rotation() || 0),
        },
        1 / multiplier,
      ),
    );
  };

  useEffect(() => {
    imageRef.current?.scaleX(1);
    imageRef.current?.scaleY(1);
    imageRef.current?.rotation(Number(boundingBox.rotateAngle || 0));
  }, [boundingBox]);

  const boundProductImage = (vec: Konva.Vector2d): Konva.Vector2d => {
    if (!imageRef.current || !stageRef.current) {
      return vec;
    }

    const {
      attrs: { width, height, scaleX, scaleY, rotation },
    } = imageRef.current;

    const rotationInRadians = (rotation * Math.PI) / 180.0;

    const actualWidth = width * scaleX;
    const actualHeight = height * scaleY;

    // Find the 4 coordinates (x,y) of the boudning box with rotation
    const corners = [
      { x: 0, y: 0 },
      { x: actualWidth, y: 0 },
      { x: actualWidth, y: actualHeight },
      { x: 0, y: actualHeight },
    ].map((corner) => {
      const x = corner.x * Math.cos(rotationInRadians) - corner.y * Math.sin(rotationInRadians) + vec.x;
      const y = corner.x * Math.sin(rotationInRadians) + corner.y * Math.cos(rotationInRadians) + vec.y;
      return { x, y };
    });

    const minX = Math.min(...corners.map((corner) => corner.x));
    const maxX = Math.max(...corners.map((corner) => corner.x));
    const minY = Math.min(...corners.map((corner) => corner.y));
    const maxY = Math.max(...corners.map((corner) => corner.y));

    const newVec = { ...vec };

    // Prevent the position of the bounding box from exceeding the max width and height of the canvas
    if (minX < 0) newVec.x += -minX;
    if (maxX > stageRef.current.width()) newVec.x -= maxX - stageRef.current.width();

    if (minY < 0) newVec.y += -minY;
    if (maxY > stageRef.current.height()) newVec.y -= maxY - stageRef.current.height();

    return newVec;
  };

  const stageRef = useRef<Konva.Stage>(null);
  return (
    <Stage ref={stageRef} width={canvasWidth} height={canvasHeight} x={0} y={0}>
      <Layer>
        {backgroundImage && <KonvaImage width={canvasWidth} height={canvasHeight} image={backgroundImage} cornerRadius={10} />}
        {ready && (
          <>
            <KonvaImage
              draggable
              x={adjustedBoundingbox.left}
              y={adjustedBoundingbox.top}
              width={adjustedBoundingbox.width}
              height={adjustedBoundingbox.height}
              rotation={Number(adjustedBoundingbox.rotateAngle)}
              image={productImage}
              ref={imageRef}
              onDragEnd={(e) => {
                getAttributesFromDragEvent(e.currentTarget);
              }}
              onTransformEnd={(e) => {
                getAttributesFromTransformEvent(e.currentTarget);
              }}
              dragBoundFunc={(vec: Konva.Vector2d) => boundProductImage(vec)}
            />
            <Transformer
              keepRatio
              enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
              ref={transformerRef}
              rotateEnabled
              rotateAnchorCursor="auto"
              rotation={Number(adjustedBoundingbox.rotateAngle)}
              flipEnabled={false}
              anchorStroke="#30C1FF"
              borderStroke="#30C1FF"
              boundBoxFunc={(oldBox, newBox) => {
                if (!stageRef.current) return oldBox;
                const { x, y, width, height } = newBox;
                const isOut = x < 0 || y < 0 || x + width > stageRef.current.width() || y + height > stageRef.current.height();
                if (isOut) {
                  return oldBox;
                }
                return newBox;
              }}
              anchorStyleFunc={(anchor) => {
                anchor.cornerRadius(1);
                if (anchor.hasName('rotater')) {
                  anchor.cornerRadius(anchor.width() / 2);
                }
              }}
              onTransformEnd={(e) => {
                getAttributesFromTransformEvent(e.currentTarget);
              }}
            />
          </>
        )}
      </Layer>
    </Stage>
  );
};
