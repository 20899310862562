export enum WeblabTreatment {
  C = 'C',
  T1 = 'T1',
  T2 = 'T2',
}

export interface Weblab {
  name: string;
  treatment: WeblabTreatment;
}

export const AICS_WEBLAB_SPLIT_GENERATE_BUTTON = 'AICS_1122014';
export const AICS_WEBLAB_FEEDS_DEACTIVATION = 'AICS_1075347';
export const AICS_WEBLAB_EDIT_TAB_POSITION_EDIT = 'AICS_1095436';
export const AICS_WEBLAB_SELECTOR_FOR_SPONSOR_BRAND = 'AICS_1109632';
export const AICS_WEBLAB_FEEDS_IN_PLAYGROUND = 'AICS_1121243';
export const AICS_WEBLAB_LIVE_IMAGE_EDIT = 'AICS_1114141';
// Feature: Bring Your Own Image
// - allow users to upload an image to the Feed from their computer
export const AICS_WEBLAB_BRING_YOUR_OWN_IMAGE = 'AICS_1105868';

export const CREATIVE_AGENT_ACCESS_CONTROL_LIST: Weblab[] = [{ name: 'RCS_MONS_AUTH_1072881', treatment: WeblabTreatment.T1 }];
