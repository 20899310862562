import { Text } from '@amzn/storm-ui';
import React from 'react';
import Lottie from 'react-lottie';
import starsAnimation from 'src/animations/Star C Generating.json';
import styles from './LoadingAnimation.module.scss';

export interface LoadingAnimationProps {
  primaryMessage?: string;
  secondaryMessage?: string;
  className?: string;
  style?: React.CSSProperties;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: starsAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const LoadingAnimation = ({ primaryMessage, secondaryMessage, className, style }: LoadingAnimationProps) => {
  return (
    <div className={`${styles.loadingAnimation} ${className || ''}`} style={style || {}}>
      {(primaryMessage?.length || secondaryMessage?.length) && (
        <div className={styles.messages}>
          {primaryMessage?.length && (
            <div className={styles.primaryMessage}>
              <Lottie
                style={{
                  // helps with centering
                  margin: '-10px',
                }}
                options={defaultOptions}
                height={80}
                width={80}
              />
              <Text className={styles.primaryMessageText} size="large" inline={true}>
                {primaryMessage}
              </Text>
            </div>
          )}
          {secondaryMessage?.length && (
            <div className={styles.secondaryMessage}>
              <Text className={styles.secondaryMessageText} size="medium" inline={true}>
                {secondaryMessage}
              </Text>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
