export const FAILURE_MESSAGE = {
  BLANK_ASIN: 'ASIN can not be blank.',
  NO_ASIN: 'We didn’t recognize that product, please enter a different product and try again.',
  INVALID_ASIN: 'ASINs can have a maximum of 10 characters only',
  NO_IMAGE: 'We’re sorry, your images were filtered out due to policy. Please reformulate your inputs and try again.',
  TIME_OUT: 'We’re sorry, this job ran for too long, and was cancelled. Please try again.',
  SYSTEM_FAILURE: 'We’re sorry, this request failed, please try again.',
  PARTIAL_FAILURE: "We're sorry, some of your images could not be generated.",
  RESTRICTED_ASIN: 'This ASIN product type is not supported in AI creative studio at this time',
};

const SubmitNotification = ({ message }: { message: string }) => {
  return <>{message}</>;
};

export const SubmitBlankAsinNotification = () => <SubmitNotification message={FAILURE_MESSAGE.BLANK_ASIN} />;
export const SubmitAsinFailureNotification = () => <SubmitNotification message={FAILURE_MESSAGE.NO_ASIN} />;
export const SubmitRestrictedAsinNotification = () => <SubmitNotification message={FAILURE_MESSAGE.RESTRICTED_ASIN} />;
export const SubmitNoImageNotification = () => <SubmitNotification message={FAILURE_MESSAGE.NO_IMAGE} />;
export const SubmitTimeoutNotification = () => <SubmitNotification message={FAILURE_MESSAGE.TIME_OUT} />;
export const SubmitSystemFailureNotification = () => <SubmitNotification message={FAILURE_MESSAGE.SYSTEM_FAILURE} />;
export const SubmitPartialFailureNotification = () => <SubmitNotification message={FAILURE_MESSAGE.PARTIAL_FAILURE} />;
