import { Button, Text } from '@amzn/storm-ui';

export default function (props: any) {
  const { disabled, useReturn, clickHandler, text, width, fullscreen, style, smlHeight, loading } = props;
  return (
    <Button
      disabled={disabled}
      variant="primary"
      loading={loading}
      loadingLabel={'Downloading'}
      style={{
        backgroundColor: disabled || loading ? '' : '#4305F4',
        width: fullscreen ? '100%' : width,
        minHeight: smlHeight ? '32px' : '42px',
        height: smlHeight ? '32px' : '42px',
        ...style,
      }}
      onClick={clickHandler}
      onKeyDown={(event: any) => {
        if (event.key === 'Enter' && useReturn) {
          clickHandler(event);
        }
      }}
    >
      <Text style={{ fontSize: smlHeight ? '13' : '15px', fontWeight: '500' }}>{text}</Text>
    </Button>
  );
}
