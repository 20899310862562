import { Icon, Popover, Text, TextButton } from '@amzn/storm-ui';
import { close } from '@amzn/storm-ui-icons';
import React, { forwardRef, useContext, useEffect, useRef } from 'react';
import { PrimaryButtonInverted } from 'src/components/_common/buttons/PrimaryButton';
import { SecondaryButtonInverted } from 'src/components/_common/buttons/SecondaryButton';
import styles from './FeedbackAlert.module.scss';
import './FeedbackAlert.css';
import { FEEDBACK_COMMENT_MODAL_ID, FeedbackCommentModal } from './FeedbackCommentModal';
import { FEEDBACK_CONTROL_CLASSNAME } from './FeedbackConstants';
import { FeedbackContext, FeedbackSentiment } from './FeedbackContext';
import styled from 'styled-components';

const PopoverComponent = styled(Popover)`
  border: none;
`;

export interface FeedbackAlertContentProps {
  handleClosePopper: () => void;
  handleDismissClicked: () => void;
  handleAddCommentClicked: () => void;
}

export const FeedbackAlertContent = forwardRef<HTMLDivElement, FeedbackAlertContentProps>((props, ref) => {
  return (
    <div className={styles.feedbackAlert} ref={ref}>
      <div className={styles.header}>
        <Text inline={true} style={{ color: 'inherit' }}>
          Thanks for your feedback!
        </Text>
        <TextButton onClick={props.handleClosePopper}>
          <Icon type={close} />
        </TextButton>
      </div>
      <div className={styles.body}>We&apos;d love to hear more about your thoughts on this asset. Please help us by adding a comment</div>
      <div className={styles.footer}>
        <SecondaryButtonInverted onClick={props.handleDismissClicked}>Dismiss</SecondaryButtonInverted>
        <PrimaryButtonInverted onClick={props.handleAddCommentClicked}>Add a comment</PrimaryButtonInverted>
      </div>
    </div>
  );
});

export interface FeedbackAlertProps {
  associatedFeedbackSentiment: FeedbackSentiment;
  trigger: React.ReactElement;
  triggerRef: React.RefObject<HTMLElement>;
}

export const FeedbackAlert = (props: FeedbackAlertProps) => {
  const feedbackAlertContentRef = useRef<HTMLDivElement>(null);
  const feedbackContext = useContext(FeedbackContext);
  const feedbackContextRef = useRef<typeof feedbackContext>();
  feedbackContextRef.current = feedbackContext;

  // Add an auto-close mechanism when clicking outside the feedback alert
  const onMouseClick = (event: MouseEvent) => {
    const feedbackContext = feedbackContextRef.current;
    const feedbackAlertEl = feedbackAlertContentRef.current;
    if (!feedbackContext || !feedbackAlertEl) return;
    if (feedbackContext.isFeedbackPopoverOpen && feedbackContext.feedbackSentiment === props.associatedFeedbackSentiment) {
      const eventTarget = event.target;
      if (!feedbackAlertEl.contains(eventTarget as Node)) {
        if (eventTarget && 'closest' in eventTarget && (eventTarget as HTMLElement).closest(`#${FEEDBACK_COMMENT_MODAL_ID}`)) {
          return;
        }

        // TODO: find a way to allow another feedback control to be clicked
        // without having its popper close due to re-rendering.
        // For example, once the submitted feedback is applied to a ContentItem, the entire list
        // is re-rendered. Perhaps this will be improved after introducing state management
        if (eventTarget && 'closest' in eventTarget && (eventTarget as HTMLElement).closest(`.${FEEDBACK_CONTROL_CLASSNAME}`)) {
          event.preventDefault();
          event.stopImmediatePropagation();
        }

        feedbackContext.closeAndSubmitFeedback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', onMouseClick, true);
    return () => {
      document.removeEventListener('click', onMouseClick);
    };
  }, []);

  const handleDismiss = () => {
    feedbackContext.submitFeedback();
  };

  const handleAddAComment = () => {
    feedbackContext.setIsFeedbackCommentModalOpen(true);
  };

  return (
    <>
      <PopoverComponent
        id="studio-feedback-popper"
        isOpen={feedbackContext.isFeedbackPopoverOpen && props.associatedFeedbackSentiment === feedbackContext.feedbackSentiment}
        autoFocus={true}
        strategy="fixed"
        trigger={props.trigger}
      >
        <FeedbackAlertContent
          handleClosePopper={handleDismiss}
          handleAddCommentClicked={handleAddAComment}
          handleDismissClicked={handleDismiss}
          ref={feedbackAlertContentRef}
        />
      </PopoverComponent>
      <FeedbackCommentModal
        isOpen={feedbackContext.isFeedbackCommentModalOpen && props.associatedFeedbackSentiment === feedbackContext.feedbackSentiment}
        toggleEl={props.triggerRef}
      ></FeedbackCommentModal>
    </>
  );
};

FeedbackAlertContent.displayName = 'FeedbackAlertContent';
