import { UserDetails } from '@amzn/genaihub-typescript-client';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { clearSelectedAdvertingAccountFromLocalStorage, getSelectedAdvertisingAccountFromLocalStorage } from 'src/helpers';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import { AppContext } from '../../AppContext';

export interface WithAuthenticationProps {
  children: ReactNode;
  unAuthenticated?: boolean;
  unAuthenticatedPages?: string[];
  unAuthenticatedFallback?: ReactNode;
  unAuthenticatedCallback?: (page?: string) => void;
}

export default function WithAuthentication({
  children,
  unAuthenticated,
  unAuthenticatedPages,
  unAuthenticatedFallback,
  unAuthenticatedCallback,
}: WithAuthenticationProps) {
  const { activePage, authenticated, userDetails, setAuthenticated, setUserDetails, setAccountType, setSelectedAdvertisingAccount } =
    useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(true);
  const genAIBackendClient = useAIBackendHubClient();

  const getUserDetails = async () => {
    try {
      const result = await genAIBackendClient.getUserDetails({});

      if (!result.body) {
        throw new Error('Response not found');
      }

      const { alias, isExternal } = result.body;
      const userDetails: UserDetails = { alias, isExternal };

      if (!userDetails.alias) {
        // not part of the weblab nor midway
        setAuthenticated(false);
        setSelectedAdvertisingAccount(null);
      } else {
        setAuthenticated(true);
        setUserDetails(userDetails);
        setAccountType(userDetails.isExternal ? 'external' : 'internal');
        // If external load the last set account before children render
        if (userDetails.isExternal && localStorage.getItem('userDetails') === alias) {
          setSelectedAdvertisingAccount(getSelectedAdvertisingAccountFromLocalStorage());
        } else {
          setSelectedAdvertisingAccount(null);
          clearSelectedAdvertingAccountFromLocalStorage();
        }
        localStorage.setItem('userDetails', alias || 'undefined');
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    setAuthenticated(false);
    if (!authenticated || !userDetails) {
      getUserDetails();
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loading && !authenticated && !unAuthenticated && !unAuthenticatedPages?.includes(activePage)) {
      unAuthenticatedCallback?.(activePage);
    }
  }, [loading, activePage, authenticated, unAuthenticated]);

  return <>{loading ? '' : authenticated || unAuthenticated || unAuthenticatedPages?.includes(activePage) ? children : unAuthenticatedFallback}</>;
}
