import { createContext } from 'react';
import { Feedback } from 'src/components/feedback/FeedbackContext';
import { ProductImageDetails, StudioInputSettings, StyleItem } from 'src/components/pages/studio/StudioContext';
import { BatchResult } from 'src/hooks/useWorkflow';
import { AssetType, WorkflowId } from './components/utils';

export interface ImageModalContextType {
  // Preview Data
  imageType: string;
  imageReferenceId: string;
  setImageReferenceId: (imageReferenceId: string) => void;
  imageUrl: string;
  prompt: string;
  studioInputSettings: StudioInputSettings | null;
  suggestedHeadline: string;
  assetType: AssetType;
  productImageDetails?: ProductImageDetails;
  theme?: StyleItem;
  // Edit Data
  editMode?: string;
  setEditMode: (editMode: string) => void;
  savedEditsImageUrl?: string;
  setSavedEditsImageUrl: (imageUrl: string | undefined) => void;
  savedEditsImageReferenceId?: string;
  setSavedEditsImageReferenceId: (imageReferenceId: string | undefined) => void;
  savedEditsImageAspectRatio?: string;
  setSavedEditsImageAspectRatio: (format: string | undefined) => void;
  savedEditsBatchId?: string;
  setSavedEditsBatchId: (batchId: string | undefined) => void;
  savedEditsWorkflowId?: WorkflowId;
  setSavedEditsWorkflowId: (workflowId: WorkflowId | undefined) => void;
  savedEditsImageId?: string;
  setSavedEditsImageId: (imageId: string | undefined) => void;
  savedEditsAssetType?: AssetType;
  setSavedEditsAssetType: (assetType: AssetType) => void;
  // Active Edit Data
  unsavedWork: boolean;
  isPublishingAsset: boolean;
  setIsPublishingAsset: (isPublishingAsset: boolean) => void;
  activeEditResults?: BatchResult;
  setActiveEditResults: (results: BatchResult | undefined) => void;
  activeEditErrorMessage?: string;
  setActiveEditErrorMessage: (error: string | undefined) => void;
  activeEditsImageUrl?: string;
  setActiveEditsImageUrl: (activeEditsImageUrl: string | undefined) => void;
  activeEditsOriginalImageUrl?: string;
  setActiveEditsOriginalImageUrl: (activeEditsImageUrl: string | undefined) => void;
  activeEditsImageReferenceId?: string;
  setActiveEditsImageReferenceId: (referenceId: string | undefined) => void;
  activeEditsImageFeedback?: Feedback;
  setActiveEditsImageFeedback: (feedback: Feedback | undefined) => void;
  activeEditsAspectRatio?: string;
  setActiveEditsAspectRatio: (aspectRatio: string | undefined) => void;
  activeEditsWorkflowId?: WorkflowId;
  setActiveEditsWorkflowId: (workflowId: WorkflowId | undefined) => void;
  activeEditsAssetType?: AssetType;
  setActiveEditsAssetType: (assetType: AssetType) => void;
  clearActiveEdit: () => void;
  //
  handleReuseSettings: () => void;
  handleSwitchToNextContentItem: () => void;
  handleSwitchToPrevContentItem: () => void;
  pendingGeneration: boolean;
  setPendingGeneration: (pendingGeneration: boolean) => void;
}

const ImageModalContext = createContext<ImageModalContextType>({} as ImageModalContextType);

export { ImageModalContext };
