import { Text, TextButton } from '@amzn/storm-ui';
import { useContext } from 'react';
import { EditorContextP1 as EditorContext } from 'src/components/editor/EditorContext';

export default function UserPrompt(props: { result?: { prompt?: string } }) {
  const context = useContext(EditorContext);
  const prompt = props?.result?.prompt;
  const handleClick = () => {
    context.setPromptUpdateEvent(prompt);
  };

  return (
    <>
      {prompt && prompt.length > 0 && (
        <>
          <Text style={{ paddingBottom: '5px', fontStyle: 'italic' }} renderAs="p" color="tertiary" size="small">
            {prompt}{' '}
            <TextButton style={{ color: '#4305F4' }} onClick={handleClick}>
              Edit this Prompt
            </TextButton>
          </Text>
        </>
      )}
    </>
  );
}
