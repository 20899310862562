import { useBundle } from '@amzn/react-arb-tools';
import { Alert, Link, Text } from '@amzn/storm-ui';
import { getErrorResourceKeyDetailsByCode } from 'src/api/errorTypes';
import { ALERTS_MESSAGE_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';
import { AlertEventType } from '../types';

export const AlertBox = (props: { onClose?: () => void; alertEvent?: AlertEventType }) => {
  const [bundle] = useBundle(ALERTS_MESSAGE_BUNDLE_NAME);
  return (
    <Alert onClose={props.onClose} withCloseButton={true} variant={props.alertEvent?.type} header={props.alertEvent?.header}>
      <Text>
        {props.alertEvent?.text ||
          t(
            bundle,
            getErrorResourceKeyDetailsByCode(props.alertEvent?.code) || props.alertEvent?.resourceKey || 'generic_error_text',
            props.alertEvent?.resourceArgs,
          )}
      </Text>
      <Link href={props.alertEvent?.link}>{props.alertEvent?.linkText}</Link>
    </Alert>
  );
};
