import { Button, SplitButton, SplitButtonItem, Text } from '@amzn/storm-ui';
import { FC } from 'react';
import Lottie from 'react-lottie';
import './AnimatedButton.scss';
import { StarsGenerating } from 'src/animations/animations';
import { GenerateOptions, GenerateOptionType } from 'src/components/pages/studio/UserInputContainer';

export interface AnimatedButtonProps {
  disabled: boolean;
  clickHandler: (options?: GenerateOptionType) => void;
  text: string;
  animate: boolean;
  className?: string;
  dataTestId?: string;
  width?: number;
  fullscreen?: boolean;
  style?: { [key: string]: string };
  generateOptions?: GenerateOptions;
}

export const AnimatedButton: FC<AnimatedButtonProps> = ({
  disabled,
  clickHandler,
  text,
  dataTestId,
  width,
  fullscreen,
  style,
  animate,
  generateOptions,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: animate || false,
    animationData: StarsGenerating,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const buttonContent = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        maxHeight: '32px',
        overflow: 'hidden',
        left: '-10px',
      }}
    >
      <Lottie isStopped={!animate} style={{ margin: '0px' }} options={defaultOptions} height={50} width={50} />
      <Text style={{ fontSize: '15px', fontWeight: '500', color: 'inherit' }}>{text}</Text>
    </div>
  );

  const isGenerateOptionsProvided = generateOptions && Object.keys(generateOptions).length > 0;

  const splitAnimatedButton = isGenerateOptionsProvided && (
    <SplitButton
      className={animate ? 'active-split-animated-button' : 'split-animated-button'}
      mainButtonLabel={buttonContent}
      mainButtonOnClick={() => clickHandler()}
      disabled={disabled}
      data-testid={dataTestId}
    >
      {Object.entries(generateOptions).map(([option, enabled]) => {
        return (
          <SplitButtonItem key={option} value={option} disabled={!enabled} onClick={() => clickHandler(option as GenerateOptionType)}>
            {option}
          </SplitButtonItem>
        );
      })}
    </SplitButton>
  );

  return (
    <div className="animated-button-container" aria-disabled={disabled} style={{ ...style, width: fullscreen ? '100%' : width }}>
      {isGenerateOptionsProvided ? (
        splitAnimatedButton
      ) : (
        <Button
          className={animate ? 'active-animated-button' : 'animated-button'}
          variant="primary"
          onClick={() => clickHandler()}
          disabled={disabled}
          data-testid={dataTestId}
          style={{
            borderRadius: '999px',
          }}
        >
          {buttonContent}
        </Button>
      )}
    </div>
  );
};
