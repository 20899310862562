import { BoundingBox } from '@amzn/genaihub-typescript-client';

export enum WorkflowId {
  TEXT_TO_IMAGE = 'TEXT_TO_IMAGE',
  IMAGE_THEMING = 'IMAGE_THEMING',
  IMAGE_EDITOR = 'IMAGE_EDITOR',
  LIFESTYLE_IMAGERY = 'LIFESTYLE_IMAGERY',
  GENERATIVE_RESIZING = 'GENERATIVE_RESIZING',
  PRODUCT_EDIT = 'PRODUCT_EDIT',
  PARALLAX_MOTION = 'PARALLAX_MOTION',
}

export enum AssetType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
}

export enum ImageCategory {
  PRODUCT_IMAGE = 'PRODUCT_IMAGE',
  REFERENCE_IMAGE = 'REFERENCE_IMAGE',
  MASK_IMAGE = 'MASK_IMAGE',
}
export type ImagePostionAction = {
  workFlowId: WorkflowId.PRODUCT_EDIT;
};

export enum ASPECT_RATIO {
  '1:1' = '1:1',
  '3:1' = '3:1',
  '1.91:1' = '1.91:1',
  '4:5' = '4:5',
  '9:16' = '9:16',
}

/* eslint @typescript-eslint/no-duplicate-enum-values: 0 */
export enum RESIZE_DIRECTION {
  'HORIZONTAL_RIGHT' = 'top/left',
  'HORIZONTAL_LEFT' = 'bottom/right',
  'HORIZONTAL_MIDDLE' = 'middle',
  'VERTICAL_UP' = 'bottom/right',
  'VERTICAL_DOWN' = 'top/left',
  'VERTICAL_MIDDLE' = 'middle',
}

export type ImageEditAction = {
  workFlowId: WorkflowId.IMAGE_EDITOR;
};

export type ImageStyleAction = {
  workFlowId: WorkflowId.IMAGE_THEMING;
};

export type ImageResizeAction = {
  workFlowId: WorkflowId.GENERATIVE_RESIZING;
};

export type ProductEditAction = ImagePostionAction & {
  workflowOptions: {
    imageId: string;
    boundingBox: BoundingBox;
    image_count: number;
  };
};
export type InsertAction = ImageEditAction & {
  workflowOptions: {
    task_type: 'inpainting';
    image_ref: string;
    mask_ref: string;
    seed: number;
    prompt: string;
    image_count: number;
  };
};

export type RemoveAction = ImageEditAction & {
  workflowOptions: {
    task_type: 'removal';
    image_ref: string;
    mask_ref: string;
    seed: number;
    image_count: number;
  };
};

export type StyleAction = ImageStyleAction & {
  workflowOptions: {
    input_image: string;
    theme: string;
  };
};

export type ResizeAction = ImageResizeAction & {
  workflowOptions: {
    aspect_ratio: ASPECT_RATIO;
    imageRef: string;
    layout: RESIZE_DIRECTION;
    prompt: string;
    theme: string;
  };
};

export type LiveImageAction = {
  workFlowId: WorkflowId.PARALLAX_MOTION;
  workflowOptions: {
    camera_path: string;
    out_duration: number;
    motion_intensity: number;
    image_ref: string;
    mask_ref: string;
    seed: number;
    image_count: number;
    theme: string;
  };
};

// TODO: add more actions
export type EditActions = InsertAction | RemoveAction | StyleAction | ResizeAction | ProductEditAction | LiveImageAction;
