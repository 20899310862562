import { Button } from '@amzn/storm-ui';
import AddImageIcon from 'src/icons/add-image-button-icon.svg';
import styles from './StandardButton.module.scss';
import { StandardButtonProps, StandardButtonSizes, StandardButtonTypes } from './types';

export const StandardButton = ({
  dataTestId,
  disabled = false,
  fullWidth = false,
  icon,
  label,
  onClick,
  size = StandardButtonSizes.Medium,
  style = undefined,
  type = StandardButtonTypes.Primary,
}: StandardButtonProps) => {
  const buttonSize = size && styles[size.toLowerCase()];
  const buttonType = disabled ? styles.disabled : styles[type.toLowerCase()];
  const iconColor = type === StandardButtonTypes.Primary ? styles.iconLight : styles.iconDark;
  const buttonFullWidth = fullWidth ? styles.fullWidth : '';
  const showIcon = icon && type !== StandardButtonTypes.Tertiary;

  const handleClick = () => {
    onClick();
  };

  return (
    <div
      className={[styles.standardButton, buttonSize, buttonType, iconColor, buttonFullWidth].join(' ')}
      data-testid={dataTestId}
      onClick={handleClick}
      style={style}
    >
      <Button disabled={disabled} small={true}>
        {showIcon && <AddImageIcon data-testid={`standard-button-icon-${icon && icon.toLowerCase().replace(' ', '-')}`} />}
        {label}
      </Button>
    </div>
  );
};
