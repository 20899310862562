import { QuotedMessage, Suggestion } from '@amzn/red-carpet-service-client';
import { Input, TextButton } from '@amzn/storm-ui';
import React, { useContext, useState } from 'react';
import MessageReply from 'src/components/assistant/components/messageInput/MessageReply';
import Suggestions from 'src/components/assistant/components/messageInput/suggestions';
import { CHAT_THEME } from 'src/components/assistant/theme';
import { ConversationContext } from 'src/components/pages/conversational/ChatPage';
import { useContainerDimensions } from 'src/components/utils/ContainerDimensions';
import ExclamationTriangle from 'src/icons/exclamationTriangle';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 32px;
`;

const InputWrapper = styled.div<{ sideNavOpen: boolean; disabled?: boolean }>`
  width: calc(100% - 44px);
  max-width: 1024px;
  outline: 2px solid ${({ disabled }) => (disabled ? '#D9DEE4' : CHAT_THEME.color.primaryBlue)};
  border-radius: ${CHAT_THEME.border.roundedExtra.radius};
  background-color: #fffffff2;
  pointer-events: auto;
  box-shadow: 0 15px 70px 10px rgba(42, 48, 53, 0.16);
  &:focus-within {
    outline-color: ${CHAT_THEME.color.aiPurple};
  }
`;

const StyledInput = styled(Input)`
  height: 3rem;

  > div:has(input) {
    border-radius: ${CHAT_THEME.border.roundedExtra.radius};
    padding-left: ${CHAT_THEME.spacing.smallPlus};
    padding-right: 14px;
    display: flex;
    align-items: center;
    height: 100%;
    border: none;

    &:focus-within {
      outline: none;
    }

    input + div {
      pointer-events: none;
      cursor: not-allowed;

      button svg {
        color: ${CHAT_THEME.color.primaryBlue};
      }
    }

    &:focus-within input + div {
      pointer-events: auto;
      cursor: pointer;
    }
  }
`;

const IconWrapper = styled(TextButton)`
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;
`;

interface Props {
  messageText?: string;
  disabled?: boolean;
  suggestions?: Suggestion[];
  setMessageText: (messageText: string) => void;
  sendMessage: (messageText?: string) => void;
  replyMessage?: QuotedMessage;
  clearQuoteMessage: () => void;
  inputRef?: React.Ref<HTMLInputElement>;
}

export default function MessageInput({
  messageText,
  setMessageText,
  sendMessage,
  suggestions,
  disabled,
  replyMessage,
  clearQuoteMessage,
  inputRef,
}: Props) {
  const [isHover, setIsHover] = useState<boolean>(false);
  const { isSideNavOpen, setMessageInputHeight } = useContext(ConversationContext);
  const messageInputRef = React.useRef<HTMLDivElement>(null);
  const { height: inputHeight } = useContainerDimensions(messageInputRef);

  React.useEffect(() => {
    setMessageInputHeight(inputHeight + 62);
  }, [inputHeight]);

  const onInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      sendMessage();
    }
  };

  return (
    <Wrapper ref={messageInputRef}>
      {!disabled && suggestions && suggestions.length > 0 && (
        <Suggestions suggestions={suggestions} sendMessage={sendMessage} setMessageText={setMessageText} />
      )}
      <InputWrapper sideNavOpen={isSideNavOpen} disabled={disabled}>
        {replyMessage?.content && replyMessage.content.length > 0 && (
          <MessageReply contentItem={replyMessage.content[0]} clearQuoteMessage={clearQuoteMessage} />
        )}
        <StyledInput
          id={'creative-agent-chat-input'}
          inputRef={inputRef}
          placeholder="Type a message"
          type="text"
          value={messageText}
          onKeyUp={onInputKeyUp}
          onChange={(e) => setMessageText(e.target.value)}
          onFocus={() => setIsHover(true)}
          onBlur={() => setIsHover(false)}
          disabled={disabled}
          fullWidth
          data-testid="chat-input"
          autoFocus
          suffix={
            <IconWrapper disabled={disabled} onClick={() => sendMessage()}>
              <ExclamationTriangle color={disabled ? '#b8bfcb' : isHover ? undefined : 'currentColor'} />
            </IconWrapper>
          }
        />
      </InputWrapper>
    </Wrapper>
  );
}
