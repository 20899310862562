import { Icon, Text, HelpTip } from '@amzn/storm-ui';
import { exclamationTriangle } from '@amzn/storm-ui-icons';
import styles from './ImageModalErrorCard.module.scss';

export interface ImageModalErrorCardProps {
  message: string;
}

export const ImageModalErrorCard = ({ message }: ImageModalErrorCardProps) => {
  return (
    <div className={styles.imageModalErrorCard}>
      <div className={styles.errorMessage}>
        <Icon className={styles.errorIcon} size={'lg'} type={exclamationTriangle} />
        <div className={styles.errorText}>
          <Text size="medium">
            There was a problem generating your content
            <HelpTip>
              <Text>{message}</Text>
            </HelpTip>
          </Text>
          <Text size="base">Please try again</Text>
        </div>
      </div>
    </div>
  );
};
