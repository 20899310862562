import { Button, Icon } from '@amzn/storm-ui';
import { angleInlineEnd, angleInlineStart } from '@amzn/storm-ui-icons';
import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const CARDS_GAP = 50;

const CardListWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const CardsList = styled.div<{ transformValue: number; cardHeight: number }>`
  display: flex;
  gap: ${CARDS_GAP}px;
  align-items: center;
  width: 100%;
  height: ${({ cardHeight }) => `${cardHeight}px`};
  transform: translateX(${({ transformValue }) => transformValue}px);
  transition: transform 0.5s ease-out;
`;

const CardWrapper = styled.div<{ active: boolean; cardHeight: number; disabled?: boolean }>`
  position: relative;
  height: ${({ active, cardHeight }) => (active ? `${cardHeight}px` : `${cardHeight * 0.6}px`)};
  aspect-ratio: 1 / 1;
  transition: all 0.5s ease-out;
  border-radius: 12px;
  cursor: ${({ disabled }) => (disabled ? undefined : 'pointer')};
`;

const CardHeadline = styled.p<{ active: boolean; cardHeight: number }>`
  position: absolute;
  margin: 0;
  bottom: 0;
  font-size: ${({ active, cardHeight }) => (active ? `${cardHeight * 0.09}px` : `${cardHeight * 0.06}px`)};
  font-weight: 800;
  line-height: 1.4em;
  letter-spacing: 0.01em;
  text-align: left;
  color: #ffffff;
  padding: 40px 10px 10px 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0.7) 75%);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  transition: all 0.5s ease-out;
`;

const CardCaptions = styled.p<{ active: boolean; cardHeight: number }>`
  position: absolute;
  margin: 0;
  top: 0;
  color: #ffffff;
  padding: 10px 10px 40px 10px;
  font-size: ${({ active, cardHeight }) => (active ? `${cardHeight * 0.08}px` : `${cardHeight * 0.048}px`)};
  font-weight: 700;
  line-height: 1.3em;
  letter-spacing: 0.01em;
  text-align: left;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0.7) 75%);
  border-radius: 12px;
  opacity: 70%;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  transition: all 0.5s ease-out;
`;

const CardImage = styled.img<{ active: boolean }>`
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 12px;
  object-position: 50% 50%;
  object-fit: cover;
  transition: all 0.5s ease-out;
`;

const StyledButton = styled(Button).attrs({ transparent: true })`
  border: none;
  border-radius: 50px;
  background: transparent;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;

  padding: 0;
  width: 70px;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;

  &&:hover {
    background-color: rgba(0, 0, 0, 0.5);
    svg {
      color: #fff;
    }
  }

  & + & {
    left: unset;
    right: 10px;
  }
`;

const StyledIcon = styled(Icon)`
  color: #ced3dc;
`;

interface Props {
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  images: { source: string; headline?: string; caption?: string }[];
  onImageSelect: (index: number) => void;
  loading?: boolean;
}

export function ImageCarousel({ setActiveIndex, activeIndex, images, onImageSelect, loading }: Props) {
  const [transformValue, setTransformValue] = useState(0);
  const [cardHeight, setCardHeight] = useState(0);
  const listRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const updateH = () => {
      setCardHeight(Math.min(Math.max(window.innerHeight - 430, 120), 360));
    };
    window.addEventListener('resize', updateH);
    updateH();
    return () => window.removeEventListener('resize', updateH);
  }, []);

  useLayoutEffect(() => {
    const observeList = listRef.current;
    if (!observeList) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width: pageWidth, height: activeCardSize } = entry.contentRect;
        const inActiveCardSize = activeCardSize * 0.6;
        setTransformValue(pageWidth / 2 - activeCardSize / 2 - activeIndex * (inActiveCardSize + CARDS_GAP));
      }
    });

    resizeObserver.observe(observeList);

    return () => {
      resizeObserver.unobserve(observeList);
    };
  }, [listRef, activeIndex]);

  const imageClickHandle = (index: number) => {
    if (index === activeIndex) {
      onImageSelect(index);
    } else {
      setActiveIndex(index);
    }
  };

  const handleNextImageClick = () => {
    setActiveIndex((prev) => (prev + 1) % images.length);
  };

  const handlePreviousImageClick = () => {
    setActiveIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <CardListWrapper>
      <CardsList cardHeight={cardHeight} transformValue={transformValue} ref={listRef}>
        {images.map((image, index) => (
          <CardWrapper
            data-testid="welcome-page-card-wrapper"
            key={image.source}
            active={index === activeIndex}
            cardHeight={cardHeight}
            onClick={() => {
              if (!loading) imageClickHandle(index);
            }}
            disabled={loading}
          >
            <CardImage src={image.source} active={index === activeIndex} />
            {image.headline && (
              <CardHeadline active={index === activeIndex} cardHeight={cardHeight}>
                {image.headline}
              </CardHeadline>
            )}
            {image.caption && (
              <CardCaptions active={index === activeIndex} cardHeight={cardHeight}>
                {image.caption}
              </CardCaptions>
            )}
          </CardWrapper>
        ))}
      </CardsList>
      <StyledButton disabled={loading} onClick={handlePreviousImageClick}>
        <StyledIcon size="3x" type={angleInlineStart} />
      </StyledButton>
      <StyledButton disabled={loading} onClick={handleNextImageClick}>
        <StyledIcon size="3x" type={angleInlineEnd} />
      </StyledButton>
    </CardListWrapper>
  );
}
