import { Suggestion as SuggestionModel } from '@amzn/red-carpet-service-client';
import { Button } from '@amzn/storm-ui';
import { CHAT_THEME, ScrollBar } from 'src/components/assistant/theme';
import styled from 'styled-components';

interface Props {
  suggestions: SuggestionModel[];
  setMessageText: (messageText: string) => void;
  sendMessage: (messageText: string) => void;
}

// FIXME: add some sort of shadows when scrollable.
const Wrapper = styled.div`
  ${ScrollBar};
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 10px;
  overflow: visible;
  white-space: nowrap;
  justify-content: center;
  width: 100%;
  pointer-events: auto;
`;

const Suggestion = styled(Button)`
  display: flex;
  padding-inline: ${CHAT_THEME.spacing.mediumPlus};
  height: 2rem;
  align-items: center;
  border-radius: 16px;
  border: 1px solid ${CHAT_THEME.color.primaryBlue};
  background: #ffffff;
  color: ${CHAT_THEME.color.primaryBlue} !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 2rem;
  box-shadow: rgba(42, 48, 53, 0.12) 0px 6px 40px 5px;

  &&:visited,
  &&:active,
  &&:hover {
    background: #ffffff !important;
    color: ${CHAT_THEME.color.aiPurple} !important;
    border: 1px solid ${CHAT_THEME.color.aiPurple};
    box-shadow: rgba(42, 48, 53, 0.12) 0px 6px 40px 5px;
  }
`;

export default function Suggestions({ suggestions, setMessageText, sendMessage }: Props) {
  const onClick = (suggestion: SuggestionModel) => {
    if (!suggestion.prompt) throw new Error('No prompt provided for suggestion');
    if (suggestion.isDraft) {
      setMessageText(suggestion.prompt);
    } else {
      sendMessage(suggestion.prompt);
    }
  };

  return (
    <Wrapper>
      {suggestions.map((suggestion, index) => (
        <Suggestion data-testid="suggestion" key={index} onClick={() => onClick(suggestion)}>
          {suggestion.shortText}
        </Suggestion>
      ))}
    </Wrapper>
  );
}
