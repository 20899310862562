import { getCookieByName, setCookie } from 'src/util/cookies';
import { AICS_WEBLAB_SELECTOR_FOR_SPONSOR_BRAND, CREATIVE_AGENT_ACCESS_CONTROL_LIST, Weblab, WeblabTreatment } from 'src/util/weblab/config';

// Mons weblab headers: https://w.amazon.com/bin/view/Mons/Weblab/Configuration/#HDeliveryFrontend:HTMLResponseMetaTagFormat
function parseMonsWeblabHeader() {
  const weblabContent = document.head.querySelector('meta[name="a2z:mons_weblab_treatment"]')?.getAttribute('content');
  if (!weblabContent) {
    return {};
  }

  return weblabContent.split(',').reduce((obj, weblabNameTreatment) => {
    const [weblabName, treatment] = weblabNameTreatment.split('=', 2);
    return {
      ...obj,
      [weblabName]: treatment,
    };
  }, {});
}

// Local dev NeoWeblab: "AICS_123:C&AICS_456:T1"
function parseNeoWeblabCookie() {
  const neoWeblabCookie = getCookieByName('experiment');
  if (!neoWeblabCookie) {
    return {};
  }

  return neoWeblabCookie.split('&').reduce((obj, weblabNameTreatment) => {
    const [weblabName, treatment] = weblabNameTreatment.split(':', 2);
    return {
      ...obj,
      [weblabName]: treatment,
    };
  }, {});
}

let weblabTreatmentCache: Record<string, string> | null = null;

export function clearWeblabCache() {
  weblabTreatmentCache = null;
}

function getWeblabTreatment(weblabName: string): string | undefined {
  if (!weblabTreatmentCache) {
    weblabTreatmentCache = {
      ...parseMonsWeblabHeader(),
      ...parseNeoWeblabCookie(),
    };
  }

  return weblabTreatmentCache[weblabName];
}

export function isWeblabInTreatment(weblabName: string, treatment: WeblabTreatment) {
  const weblabTreatment = getWeblabTreatment(weblabName);
  return !!(weblabTreatment && weblabTreatment === treatment);
}

export function isValidWeblabTreatment(validWebLabs: Weblab[]) {
  for (const weblab of validWebLabs) {
    if (isWeblabInTreatment(weblab.name, weblab.treatment)) {
      return true;
    }
  }
  return false;
}

export const isCreativeAgentLaunched = (): boolean => {
  return isValidWeblabTreatment(CREATIVE_AGENT_ACCESS_CONTROL_LIST);
};

export const isSponsoredCampaignLaunched = (): boolean => {
  return isWeblabInTreatment(AICS_WEBLAB_SELECTOR_FOR_SPONSOR_BRAND, WeblabTreatment.T1);
};

export function getWebLabAlias() {
  const alias = getCookieByName('weblab-alias') || `weblab-${crypto.randomUUID()}`;
  setCookie('weblab-alias', alias, {
    domain: window.location.hostname.split('.').slice(-2).join('.'),
    expires: 1,
    path: '/',
  });
  return alias;
}
