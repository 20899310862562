export const checkVisibility = (el: HTMLElement): boolean => {
  // @ts-ignore - typescript doesn't recognize checkVisibility, but when using "prop" in el check, it causes later el references to become "never" ¯\_(ツ)_/¯
  if (typeof el.checkVisibility === 'function') {
    // @ts-ignore
    return el.checkVisibility({ contentVisibilityAuto: true, opacityProperty: true, visibilityProperty: true });
  }

  const style = window.getComputedStyle(el);
  if (style.visibility === 'hidden') return false;
  if (style.opacity === '0') return false;
  if (style.display === 'none') return false;

  return el.parentElement === null ? true : checkVisibility(el.parentElement);
};
