import { CHAT_THEME } from 'src/components/assistant/theme';
import styled from 'styled-components';
import TextMessage from './textMessage';

interface Props {
  loadingMessage: string;
}

const LoadingState = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #8290a4;
  animation: loading 1s infinite linear;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #8290a4;
    animation: loading-before 1s infinite linear;
  }
  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #8290a4;
    animation: loading-after 1s infinite linear;
  }

  @keyframes loading-before {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1.2, 1.2);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes loading {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.2, 1.2);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  @keyframes loading-after {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(0.8, 0.8);
    }
    75% {
      transform: scale(1.2, 1.2);
    }
    100% {
      transform: scale(1, 1);
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: calc(${CHAT_THEME.spacing.basePlus} + 15px);
  padding-inline: ${CHAT_THEME.spacing.basePlus};
  align-items: center;
`;

export default function LoadingMessage({ loadingMessage }: Props) {
  return (
    <Wrapper data-testid={'loading-message'}>
      <LoadingState />
      <TextMessage content={loadingMessage} />
    </Wrapper>
  );
}
