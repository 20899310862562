import { Text } from '@amzn/storm-ui';
import Stack from 'src/customUIComponents/Stack';
import styled from 'styled-components';

const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9000;
`;

const GridBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  color: white;
`;

const ErrorCard = () => {
  return (
    <GridContainer>
      <GridBox>
        <Stack style={{ flexDirection: 'row', justifyContent: 'center', gap: '0px', height: '100%' }}>
          <div style={{ padding: '4px 6px 0 0' }}>
            <svg viewBox="0 0 512 512" style={{ width: '16px', height: '16px' }}>
              <path
                fill="white"
                d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
              ></path>
            </svg>
          </div>
          <div>
            <Text size="medium">Sorry, something went wrong</Text>
          </div>
        </Stack>
      </GridBox>
    </GridContainer>
  );
};

export default ErrorCard;
