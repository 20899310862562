import { Icon } from '@amzn/storm-ui';
import { IconDefinition } from '@amzn/storm-ui-icons';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useState } from 'react';
import { AnimatedButton } from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/AnimatedButton';
import ControlLabel from 'src/components/editor/UiContols/uiGeneratorControls/storm/UI/ControlLabel';
import style from 'src/components/imageModal/components/ImageModalStyleSetting.module.scss';
import { ImageCategory, StyleAction, useWorkflow, WorkflowId } from 'src/components/imageModal/components/utils';
import { ImageModalContext, ImageModalContextType } from 'src/components/imageModal/ImageModalContext';
import { ALL_STYLES, getFilteredStyles } from 'src/components/utils/styleUtils';
import { HEADING_TEXT_SIZE } from 'src/constants';
import { AppContext } from '../../../../AppContext';
import themes from '../../../config/themes/themesDropdownList';
import themesConfig from '../../editor/UiContols/uiGeneratorControls/themes.json';

const ImageModalStyleSetting = () => {
  const [list, setList] = useState(themesConfig);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const { activePage, userDetails } = useContext(AppContext);
  const [theme, setTheme] = useState<ImageModalContextType['theme']>();
  const categories = ['Popular', 'Holidays', 'Location', 'Abstract'];
  const icons: Record<string, string> = { ...themes };
  const imageModalContext = useContext(ImageModalContext);
  const { imageReferenceId, imageUrl, savedEditsImageUrl, setActiveEditResults, setActiveEditErrorMessage, setPendingGeneration } =
    useContext(ImageModalContext);

  const imageRef = savedEditsImageUrl === '' && !imageUrl.includes('catwalk-generated-assets') ? imageReferenceId : savedEditsImageUrl || imageUrl;

  const {
    updateWorkflowOptions,
    submissionQuery: { data: result },
    submitWorkflow,
    isPending,
    error,
    isError,
  } = useWorkflow<StyleAction>({
    workFlowId: WorkflowId.IMAGE_THEMING,
    userAlias: userDetails?.alias,
    pageName: activePage,
    defaultWorkflowOptions: {
      theme: theme?.value || 'no_theme',
      input_image: '2770e255-1253-46a0-8524-32fc98a757a3', // needed for testing purposes only on local environment
    },
    setPendingGeneration,
  });

  useEffect(() => {
    if (result) {
      setActiveEditResults(result);
    } else {
      setActiveEditResults(undefined);
    }
  }, [result?.jobs?.[0].status]);

  useEffect(() => {
    if (isError) {
      setActiveEditErrorMessage(error?.message);
      console.error('Error while generating results', error);
    }
  }, [isError]);

  const handleStyleSelection = (id: string) => {
    const selectedStyle = list.find((item) => item.controlValue === id);
    if (selectedStyle) {
      setTheme({
        value: selectedStyle.controlValue,
        label: selectedStyle.controlLabel,
        icon: icons[selectedStyle.src],
      });

      updateWorkflowOptions({ theme: selectedStyle.controlValue });
    }
  };

  const searchHandler = (value: string) => {
    setList(getFilteredStyles(value));
  };

  const inputChangeHandler = (value: string) => {
    searchHandler(value);
    setSelectedCategory(ALL_STYLES);
  };

  const categoryClickHandler = (category: string) => {
    if (category === selectedCategory) {
      setSelectedCategory(ALL_STYLES);
      searchHandler(ALL_STYLES);
    } else {
      setSelectedCategory(category);
      searchHandler(category);
    }
  };

  const generateResults = async () => {
    imageModalContext.clearActiveEdit();
    await submitWorkflow({
      input_image: {
        urlOrFile: imageRef,
        contentCategory: ImageCategory.PRODUCT_IMAGE,
        fileTypeOverride: 'image/png',
      },
    });
  };

  return (
    <div className={style.container}>
      <ControlLabel
        title="Preset Styles"
        subTitle="To update your image with new locations, studio backgrounds, or holiday themes, choose a style and generate."
        titleSize={HEADING_TEXT_SIZE.SMALL}
      />
      <div className={style.searchBar}>
        <input
          placeholder="Search by keyword"
          onChange={(event) => inputChangeHandler(event.target.value)}
          data-testid="studio-edit-restyle-search-input"
        />
        <Icon type={faMagnifyingGlass as IconDefinition} />
      </div>
      <div className={style.pills}>
        {categories.map((category) => (
          <div
            key={category}
            onClick={() => categoryClickHandler(category)}
            className={`${style.pillButton} ${selectedCategory == category ? style.selected : ''}`}
          >
            {category}
          </div>
        ))}
      </div>
      <div className={style.tiles}>
        {list.map((item) => {
          return (
            <div
              key={item.controlValue}
              onClick={() => handleStyleSelection(item.controlValue)}
              className={style.tileItem}
              data-testid={`studio-edit-restyle-style-${item.controlValue}`}
            >
              <div
                className={`${style.tileItemIcon} ${theme?.value == item.controlValue ? style.selected : ''}`}
                style={{ backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.05) 51%, rgba(0, 0, 0, 0.5) 82%), url(${icons[item.src]})` }}
              ></div>
              <div className={style.tileItemLabel}>{item.controlLabel}</div>
            </div>
          );
        })}
      </div>
      <AnimatedButton
        dataTestId="studio-edit-generate-button"
        animate={isPending}
        disabled={!theme?.value || isPending}
        clickHandler={() => generateResults()}
        text={isPending ? 'Generating' : 'Generate'}
        fullscreen
      ></AnimatedButton>
    </div>
  );
};

export default ImageModalStyleSetting;
