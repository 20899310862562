import { Heading, Text } from '@amzn/storm-ui';
import React, { useContext, useEffect, useState } from 'react';
import { EditorContextP1 } from 'src/components/editor/EditorContext';
import { HEADING_TEXT_SIZE } from 'src/constants';

export interface ControlLabelProps {
  title: string | React.ReactElement;
  subTitle?: string;
  defaultValue?: string;
  titleSize?: HEADING_TEXT_SIZE;
}

const ControlLabel = (props: ControlLabelProps) => {
  const { title, subTitle, defaultValue, titleSize } = props;
  const editorContext = useContext(EditorContextP1);
  const [opacity, setOpacity] = useState<number>(1);

  useEffect(() => {
    if (editorContext.disableControl && defaultValue !== 'HEAD_CONTROL') {
      setOpacity(0.5);
    } else setOpacity(1);
  }, [editorContext.disableControl]);
  return (
    <div style={{ marginBottom: '12px' }}>
      {titleSize ? (
        <Heading renderAs="h2" size={titleSize || 'small'} style={{ paddingBottom: subTitle && '8px', opacity: opacity }}>
          {title}
        </Heading>
      ) : (
        <Text style={{ fontSize: '15px', fontWeight: '600', paddingBottom: subTitle && '8px', opacity: opacity }}>{title}</Text>
      )}
      {subTitle && (
        <Text style={{ fontStyle: 'italic', opacity: opacity }} renderAs="p" color="tertiary" size="small">
          {subTitle}
        </Text>
      )}
    </div>
  );
};

export default ControlLabel;
