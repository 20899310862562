import React from 'react';
import styled from 'styled-components';
import ErrorCard from './ErrorCard';
import GeneratingCard from './GeneratingCard';

const OuterContainer = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-evenly',
  height: '100%',
  alignItems: 'flex-start',
  flexGlow: 'column',
  '@keyframes fadeOutAnimation': {
    '0%': { opacity: '1' },
    '100%': { opacity: '0' },
  },
});

const fadeOutStyle = {
  animation: 'fadeOutAnimation .5s 4s ease-out',
  animationFillMode: 'forwards',
};

const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 750px;
  aspect-ratio: 681 / 453;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px;
`;

const GridContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const GridBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #2da5db;
  overflow: hidden;
  color: inherit;
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: -100%;
    left: -100%;
    width: 400%;
    height: 400%;
    filter: blur(50px);
    animation: ColorBlobs_spin__CbrXa 8s linear infinite;
    transform-origin: 50% 50%;
    background: conic-gradient(from 90deg at 50% 50%, #2da5db, #2d90e5, #7847fc, #4753fc, #2d6edb, #7847fc, #4753fc, #4b99ef);
    background-size: 100% 100%;
    overflow: visible;
  }
  &:nth-child(2) {
    &:after {
      left: -200%;
    }
  }
  &:nth-child(3) {
    &:after {
      top: -200%;
    }
  }
  &:nth-child(4) {
    &:after {
      top: -200%;
      left: -200%;
    }
  }
  @keyframes ColorBlobs_spin__CbrXa {
    0% {
      transform: rotate(0);
    }
    50% {
      transform-origin: 60%;
    }
    100% {
      transform: rotate(1turn);
    }
  }
`;

const GridItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const CardLoader = (props: { style?: React.CSSProperties; isError?: boolean }) => {
  const cardStyle: React.CSSProperties = { ...(props.style || {}), ...(props.isError ? fadeOutStyle : {}) };
  return (
    <OuterContainer style={cardStyle}>
      <InnerContainer>
        <GridContainer>
          <GridBox>
            <GridItem></GridItem>
          </GridBox>
        </GridContainer>
        {props.isError ? <ErrorCard /> : <GeneratingCard />}
      </InnerContainer>
    </OuterContainer>
  );
};

export default CardLoader;
