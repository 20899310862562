import { Dropdown, DropdownItem } from '@amzn/storm-ui';
import { useContext, useState } from 'react';
import { ImageModalContext } from 'src/components/imageModal/ImageModalContext';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import useAssetLibrary from 'src/hooks/useAssetLibrary';
import styles from './styles.module.scss';
import { AppContext } from '../../../AppContext';

export const SAVE_IMAGE_TO_ACCOUNT_DROPDOWN_TEST_ID = 'save-image-to-account-dropdown';

export interface SaveImageToAccountDropdownProps {
  assetUrl: string;
  disabled?: boolean;
}

export const SaveImageToAccountDropdown = (props: SaveImageToAccountDropdownProps) => {
  const { assetUrl } = props;
  const appContext = useContext(AppContext);
  const { imageReferenceId, studioInputSettings } = useContext(ImageModalContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveToAdsAccountDropdownValue, setSaveToAdsAccountDropdownValue] = useState();

  const useAssetLibraryHook = useAssetLibrary();
  const client = useAIBackendHubClient();

  const saveImage = async () => {
    if (!isSaving && assetUrl) {
      setIsSaving(true);
      const response = await client.retrieveAsset({
        id: imageReferenceId,
        entityId: appContext?.selectedAdvertisingAccount?.alternateIds?.[0],
      });
      const url = response?.body.asset?.uri || '';
      await useAssetLibraryHook.saveAsset({ assetUrl: url, asin: studioInputSettings?.asin?.asin });
      setIsSaving(false);
    }
  };

  /* Leaving value prop as 'any' intentionally as per documentation from storm-ui team:
  https://code.amazon.com/packages/StormUI/blobs/df7ff45190ed53c1842030025e97c84b9e6c1223/--/packages/storm-ui/src/Dropdown/Dropdown.tsx#L108
  https://code.amazon.com/packages/StormUI/blobs/6cfa532fc7324de93c94e8fc90557cb649854a4d/--/packages/storm-ui/src/SelectList/types/index.ts#L7
  */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const OverrideLabel = (dropdownItem: any, value: any) => {
    if (value) {
      return dropdownItem;
    }
    return 'Send to my ads account';
  };

  return (
    <>
      <Dropdown
        className={styles.saveToAdsAccountDropdown}
        data-testid={'save-to-ads-account-dropdown'}
        selectedValue={saveToAdsAccountDropdownValue}
        onChange={setSaveToAdsAccountDropdownValue}
        onOverrideLabel={OverrideLabel}
      >
        <DropdownItem
          value={'save-to-al'}
          disabled={props.disabled}
          className={!isSaving && styles.saveToAdsAccountDropdownItem}
          onClickCapture={saveImage}
          data-testid={SAVE_IMAGE_TO_ACCOUNT_DROPDOWN_TEST_ID + '-save-to-al'}
        >
          Save to creative assets
        </DropdownItem>
        <DropdownItem
          value={'start-a-sb'}
          className={styles.saveToAdsAccountDropdownItem}
          data-testid={SAVE_IMAGE_TO_ACCOUNT_DROPDOWN_TEST_ID + '-start-a-sb'}
        >
          Start a Sponsored Brands campaign
        </DropdownItem>
      </Dropdown>
    </>
  );
};
