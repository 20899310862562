import { AnchoredAlert, Button, Text } from '@amzn/storm-ui';
import styled from 'styled-components';
interface FeedbackAlertProps {
  userFeedback: string;
  sentiment: string;
  handleAddComment: any;
  handleDismiss: any;
  FeedbackIcon: any;
  position: any;
  style?: any;
}

const AnchoredAlertComponent = styled(AnchoredAlert)`
  button[aria-label='Close'] {
    color: #ffffff !important;
    border: none;
    &:hover {
      color: #ffffff;
    }
  }
`;

const FeedbackAlert = (props: FeedbackAlertProps) => {
  const { userFeedback, sentiment, handleAddComment, handleDismiss, FeedbackIcon, position } = props;
  const Alert = (props: any) => {
    return (
      <>
        <AnchoredAlertComponent
          align="center"
          variant="default"
          trigger={<FeedbackIcon userFeedback={userFeedback} />}
          isOpen={userFeedback.toString() === sentiment.toLowerCase()}
          onCloseButtonClick={handleDismiss}
          position={position}
          style={{ backgroundColor: '#4305f4', border: 'none' }}
        >
          {props.children}
        </AnchoredAlertComponent>
      </>
    );
  };

  return (
    <>
      <Alert>
        <div style={{ width: '300px' }}>
          <Text style={{ color: '#FFFFFF', textAlign: 'initial', fontWeight: '700', fontSize: '15px', lineHeight: '19px' }}>
            {'Thanks for your feedback!'}
          </Text>
          <Text
            style={{
              color: '#FFFFFF',
              textAlign: 'initial',
              marginBottom: '15px',
              marginTop: '5px',
              fontWeight: '400',
              fontSize: '13px',
              lineHeight: '19px',
            }}
            renderAs="p"
          >
            {"We'd love to hear more about your thoughts on this tool. Please help us improve by adding a comment"}
          </Text>
          <Button style={{ color: '#4305f4', backgroundColor: '#FFFFFF', float: 'right' }} onClick={handleAddComment} variant="primary">
            Add a comment
          </Button>
          <Button
            style={{ color: '#FFFFFF', backgroundColor: 'transparent', float: 'right', boxShadow: 'none' }}
            onClick={handleDismiss}
            variant="primary"
          >
            Dismiss
          </Button>
        </div>
      </Alert>
    </>
  );
};

export default FeedbackAlert;
