import { Button, Icon } from '@amzn/storm-ui';
import { download } from '@amzn/storm-ui-icons';
import { downloadFromUrl } from 'src/components/utils/LinkUtils';
import styled from 'styled-components';

export const PrimaryButton = styled(Button)`
  && {
    border: none;
    background-color: var(--text-color-variant-6);
    color: var(--standard-button-color-dark);
  }

  &&:hover {
    background-color: var(--AI-purple, #4305f4);
    color: white;
  }

  &&:disabled {
    background-color: var(--disabled-button-color);
  }
`;

export const DOWNLOAD_IMAGE_BUTTON_TEST_ID = 'download-image-button';
export interface DownloadImageButtonProps {
  url: string;
  disabled?: boolean;
}

export const DownloadImageButton = ({ url, disabled }: DownloadImageButtonProps) => {
  return (
    <>
      <PrimaryButton
        disabled={disabled}
        onClick={() => !disabled && downloadFromUrl({ url, fileName: `ailab-${crypto.randomUUID()}` })}
        data-testid={DOWNLOAD_IMAGE_BUTTON_TEST_ID}
      >
        <Icon type={download} /> Download
      </PrimaryButton>
    </>
  );
};
