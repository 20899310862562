import { Icon, Heading } from '@amzn/storm-ui';
import { spinner } from '@amzn/storm-ui-icons';
import SpinnerIcon from 'src/icons/spinnerIcon';

export default function (props: any) {
  return (
    <>
      <div role="alert" aria-live="assertive" style={{ display: 'flex', flexDirection: 'column', flex: 'auto', alignItems: 'center' }}>
        <SpinnerIcon style={{ width: '30px', height: '30px' }} />
        <Icon type={spinner} size={props.size || 'xs'} style={{ opacity: '0', display: 'none' }} aria-hidden="true" data-testid="spinner" />
        {props.text && (
          <Heading renderAs="h3" color="secondary" style={{ marginTop: '1rem' }}>
            Loading
          </Heading>
        )}
      </div>
    </>
  );
}
