import { AdvertisingAccountMetadata, AdvertisingAccounts, QueryAdvertisingAccountsResponse } from '@amzn/genaihub-typescript-client';
import { Heading, Text, TextButton, Icon, Modal } from '@amzn/storm-ui';
import { checkCircle } from '@amzn/storm-ui-icons';
import React, { useEffect, useState, useContext } from 'react';
import Spinner from 'src/components/common/storm/Spinner';
import Stack from 'src/customUIComponents/Stack';
import { clearSelectedAdvertingAccountFromLocalStorage, saveSelectedAdvertisingAccountToLocalStorage } from 'src/helpers';
import { useAIBackendHubClient } from 'src/hooks/useAIBackendHubClient';
import style from './AccountList.module.scss';
import { AppContext } from '../../../../AppContext';

export const TEST_ID_ACCOUNT_LIST_CONTAINER = 'account-list-container';
export const TEST_ID_ACCOUNT_LIST_MODAL = 'account-list-modal';
export const TEST_ID_ACCOUNT_LIST_TEXT_MESSAGE = 'account-list-text-message';
export const TEST_ID_ACCOUNT_LIST_ACCOUNT = 'account-list-acount';
export const TEST_ID_ACCOUNT_LIST_SPINNER = 'account-list-spinner';

export const SCROLL_LIMIT = 100;

const AccountList = () => {
  const context = useContext(AppContext);
  const genAIBackendClient = useAIBackendHubClient();

  const [advertisingAccounts, setAdvertisingAccounts] = useState<AdvertisingAccounts>();
  const [nextToken, setNextToken] = useState<QueryAdvertisingAccountsResponse['nextToken']>();
  const [waitingForNetworkResponse, setWaitingForNetworkResponse] = useState<boolean>(false);
  const [textMessage, setTextMessage] = useState<{ show: boolean; message: string }>();
  const [isManagerAccount, setIsManagerAccount] = useState<boolean>(false);

  useEffect(() => {
    if (context.authenticated) {
      context.setShowSwitchAccountModal(!context.selectedAdvertisingAccount);
      getAdvertisingAccounts();
    }
  }, [context.authenticated]);

  const getAdvertisingAccounts = async (requestToken?: string) => {
    if (waitingForNetworkResponse) {
      return;
    }

    setWaitingForNetworkResponse(true);

    try {
      const results = await genAIBackendClient.queryAdvertisingAccounts({
        body: {
          nextToken: requestToken,
          maxResults: 20,
        },
      });

      if (!results.body?.advertisingAccounts || (results.body?.advertisingAccounts?.length === 0 && context.accountType === 'external')) {
        clearSelectedAdvertingAccountFromLocalStorage();
        context.setSelectedAdvertisingAccount(null);
        setIsManagerAccount(true);
        setTextMessage({
          show: true,
          message:
            "There aren't any advertiser accounts associated with this login. You can still use AI creative studio, but you won't be able to save anything to your creative assets.",
        });
      } else {
        setAdvertisingAccounts((previousAccounts) => [...(previousAccounts || []), ...(results.body?.advertisingAccounts || [])]);
        setNextToken(results.body?.nextToken);
      }
    } catch (error) {
      setTextMessage({ show: true, message: 'Something went wrong while getting the accounts' });
    }

    setWaitingForNetworkResponse(false);
  };

  useEffect(() => {
    // if we already have an account selected but it's not in the returned list then clear it and show modal
    if (
      context.authenticated &&
      context.selectedAdvertisingAccount &&
      advertisingAccounts &&
      !advertisingAccounts?.reduce((value, account) => value || account.adsAccountId === context.selectedAdvertisingAccount?.adsAccountId, false)
    ) {
      console.log('Remove saved selectedAdvertisingAccount', context.selectedAdvertisingAccount, advertisingAccounts);
      clearSelectedAdvertingAccountFromLocalStorage();
      context.setSelectedAdvertisingAccount(null);
      context.setShowSwitchAccountModal(true);
    }
  }, [context.authenticated, context.selectedAdvertisingAccount, advertisingAccounts]);

  const closeMenu = () => {
    if (!context.selectedAdvertisingAccount && !isManagerAccount) {
      setTextMessage({ show: true, message: 'Please select an account before closing' });
    } else {
      if (!isManagerAccount) {
        setTextMessage({ show: false, message: '' });
      }
      context.setShowSwitchAccountModal(false);
    }
  };

  const chooseAccountClickHandler = (selectedAdvertisingAccount: AdvertisingAccountMetadata) => {
    saveSelectedAdvertisingAccountToLocalStorage(selectedAdvertisingAccount);
    context.setSelectedAdvertisingAccount(selectedAdvertisingAccount);
    context.setShowSwitchAccountModal(false);
  };

  const onScrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollableElement = event.target as HTMLDivElement;
    if (
      !waitingForNetworkResponse &&
      scrollableElement.scrollHeight - (scrollableElement.scrollTop + scrollableElement.offsetHeight) < SCROLL_LIMIT &&
      nextToken
    ) {
      getAdvertisingAccounts(nextToken);
    }
  };

  return (
    <>
      <div data-testid={TEST_ID_ACCOUNT_LIST_CONTAINER} className={style.accountList}>
        <Modal
          data-testid={TEST_ID_ACCOUNT_LIST_MODAL}
          isOpen={context.showSwitchAccountModal}
          header="Choose an account"
          onClose={closeMenu}
          modalElementId="choose-account-modal"
          hasCloseButton={!!context.selectedAdvertisingAccount || isManagerAccount}
          onScrollCapture={onScrollHandler}
        >
          <div id="aaaa" className={`${style.modalContent} ${nextToken ? style.loadMore : ''}`}>
            <Stack style={{ flexDirection: 'column', alignItems: 'flex-start', gap: '12px' }}>
              <Heading renderAs={'h3'}>All accounts</Heading>
              {advertisingAccounts?.map((account: AdvertisingAccountMetadata, index: number) => (
                <div data-testid={TEST_ID_ACCOUNT_LIST_ACCOUNT} className={style.listItem} key={index}>
                  <TextButton onClick={() => chooseAccountClickHandler(account)} className={style.text}>
                    {account.accountName}
                  </TextButton>
                  {account.adsAccountId == context.selectedAdvertisingAccount?.adsAccountId && (
                    <Icon className={style.listItemIcon} type={checkCircle} />
                  )}
                </div>
              ))}
            </Stack>
            {waitingForNetworkResponse && (
              <div data-testid={TEST_ID_ACCOUNT_LIST_SPINNER} className={style.progressIndicator}>
                <Spinner />
              </div>
            )}
            {textMessage?.show && (
              <div data-testid={TEST_ID_ACCOUNT_LIST_TEXT_MESSAGE} className={style.textMessage}>
                <Text>{textMessage.message}</Text>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AccountList;
