import { BoundingBox } from '@amzn/genaihub-typescript-client';
import { ASPECT_RATIO } from './types';
export const getSizeWithAspectRatio: (
  option:
    | { width: number; aspectRatio: ASPECT_RATIO }
    | {
        height: number;
        aspectRatio: ASPECT_RATIO;
      },
) => { width: number; height: number } = ({ aspectRatio: aspectsRatio, ...option }) => {
  const [w, h] = aspectsRatio.split(':').map((str: string) => Number.parseFloat(str));
  if (!w || !h) {
    throw new Error('Invalid aspects ratio');
  }
  if ('width' in option) {
    const { width } = option;
    return {
      width,
      height: width * (h / w),
    };
  }

  if ('height' in option) {
    const { height } = option;
    return {
      height,
      width: (height * w) / h,
    };
  }

  throw new Error('Size must have width or height.');
};
export const adjustBoundingBox = (boundingBox: BoundingBox, multiplier: number, rounded = true): BoundingBox => {
  const { width, height, top, left, rotateAngle } = boundingBox;
  const digits = 0;
  const pow = Math.pow(10, digits);
  const formatter = rounded ? (x: number) => Math.round(x * pow) / pow : (x: number) => x;
  return {
    width: formatter(width * multiplier),
    height: formatter(height * multiplier),
    top: formatter(top * multiplier),
    left: formatter(left * multiplier),
    rotateAngle,
  } as BoundingBox;
};
