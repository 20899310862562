import { Icon, TextButton } from '@amzn/storm-ui';
import { externalLink } from '@amzn/storm-ui-icons';
import React from 'react';
import { openNewTab } from 'src/components/utils/LinkUtils';
import { getAdConsoleUrlByStage } from 'src/util/env';

export const TEST_ID_CREATIVE_ASSETS_LINK = 'testid-creative-assets-link';
export const CREATIVE_ASSETS_URL = '/creative-assets';
export const CREATIVE_ASSETS_TEXT = 'creative assets';
export const FAILURE_TEXT = 'Sorry, something went wrong. Please try again later.';
export const PROCESSING_TEXT = 'This is taking longer than expected. To save your asset, wait a little longer or try again later.';
export const SUCCESS_TEXT = 'Asset saved to';

const CreativeAssetsLink = () => {
  const onClickLink = (e: React.MouseEvent) => {
    e.stopPropagation();
    openNewTab({ url: `${getAdConsoleUrlByStage()}${CREATIVE_ASSETS_URL}` });
  };

  return (
    <TextButton data-testid={TEST_ID_CREATIVE_ASSETS_LINK} onClick={onClickLink}>
      {CREATIVE_ASSETS_TEXT} <Icon type={externalLink} />
    </TextButton>
  );
};

export const AddToAssetLibraryProcessingNotification = () => {
  return <>{PROCESSING_TEXT}</>;
};

export const AddToAssetLibraryFailureNotification = () => {
  return <>{FAILURE_TEXT}</>;
};

export const AddToAssetLibrarySuccessNotification = () => {
  return (
    <>
      {SUCCESS_TEXT}&nbsp;
      <CreativeAssetsLink />
    </>
  );
};
